<template>
  <v-container fluid>
    <v-row no-gutters class="pos">
        <v-col md="9" class="pa-4">
            <v-row>
                <v-col md="6">
                    <div class="product-search">
                        <v-text-field
                            v-model="productNameSearch"
                            append-icon="mdi-search"
                            dense
                            flat
                            label="Search Product"
                            solo
                            @keyup.enter="getProductList"
                            @click:append="getProductList"
                            clearable
                            @click:clear="clearSearch"
                            class="ps-field"
                        ></v-text-field>
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="6">
                    <div class="ptBtn float-right" v-if="checkReadPermission($modules.pos.management.slug)">
                        <router-link :to="`/pos/product-management`" class='main-color'><v-icon class='main-color' >mdi-cog-outline</v-icon> Product Management</router-link>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="12">
                    <div class="pos-cat ma-2"><h4>Categories</h4></div>
                    <div class="pos-cat-list">
                        <template>
                            <v-tabs class="cat-tab"  v-model="selectedTab" @change="handleTabChange" show-arrows>
                                <v-tab v-for="(cat,index) in categories"  :key="index" :category-id="cat.id">{{cat.name}}</v-tab>
                            </v-tabs>
                        </template>
                    </div>
                </v-col>
            </v-row>
             <v-row  v-if="products.length">
                <v-col lg="3" md="4" sm="6"  v-for="(product,index) in products" :key="index">
                    <PosProductItem v-bind="product" :product_image="getImage(product.image)" @addToCart="addToCart" :key="`p_${index}`" :refreshProductItem="refreshProductItem" />
                </v-col>
            </v-row>
            <v-row sm="12">
                <v-col>
                    <v-pagination
                        v-if="totalPages > 0"
                        v-model="page"
                        :length="totalPages"
                    ></v-pagination>
                </v-col>
            </v-row>
        </v-col>
        <v-col md="3">
            <div class="pos-right-drawer">
                <template>
                    <v-card class="mx-auto fixed-height-container">
                        <v-navigation-drawer permanent style="width:98%;">
                            <v-list-item>
                                <v-list-item-content>
                                <v-list-item-title class="pos-title-text">
                                    Your Order 
                                    <span @click="clearCart" text class="float-right clear-cart-text">Clear Cart</span>
                                </v-list-item-title>
                                    
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                            <div class="empty-cart" v-if="!cartItems.length"> <img :src="getImage(null,'cart')" aspect-ratio="1" /></div>
                            <v-list v-if="cartItems.length" class="cart-sidebar-item">
                                <v-list-item-group v-for="(product,cartIndex) in cartItems" :key="cartIndex" >
                                <v-list-item class="cart-product-list-item">
                                    <div class="cart-product-image">
                                        <img :src="getImage(product.image)" aspect-ratio="1" />
                                    </div>
                                    <v-list-item-content class="cart-product-description">
                                        <v-list-item-title class="cart-product-title">{{ product.name }}</v-list-item-title>
                                        <v-list-item-subtitle class="cart-product-meta">
                                            <div class="price main-color">{{ product.price | toCurrency }}</div>
                                            <div class="cart-product-qty">
                                                <div class="plus-qty">
                                                    <v-btn @click="decrementQty(cartIndex)" icon>
                                                        <v-icon>mdi-minus</v-icon>
                                                    </v-btn>
                                                </div>
                                                <div class="input-qty">
                                                    {{ product.quantity }}
                                                </div>
                                                <div class="minus-qty">
                                                    <v-btn @click="incrementQty(cartIndex)" icon>
                                                      <v-icon>mdi-plus</v-icon>
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <div class="cart-product-summary">
                                <div class="row">
                                    <div class="col-md-6"><div class="cart-subtotal">Sub Total</div></div>
                                    <div class="col-md-6"><div class="cart-subtotal-price">{{ calculateCartSummary.subtotal | toCurrency }}</div></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6"><div class="cart-subtotal">Taxes (5%)</div></div>
                                    <div class="col-md-6"><div class="cart-subtotal-price">{{ calculateCartSummary.tax | toCurrency }}</div></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6"><div class="cart-total">Total</div></div>
                                    <div class="col-md-6"><div class="cart-total-price">{{ calculateCartSummary.total | toCurrency }}</div></div>
                                </div>
                                <div class="row" v-if="checkWritePermission($modules.pos.dashboard.slug)">
                                    <div class="col-md-12 pl-0 pr-0"> 
                                        <v-btn class="cart-checkout-btn" :disabled="cartItems.length == 0" @click="showCustomerPopup">Checkout</v-btn>
                                    </div>
                                </div>
                            </div>

                        </v-navigation-drawer>
                    </v-card>
                </template>
            </div>
        </v-col>
    </v-row>
    <PosCustomerPopup 
        :customerDialog="customerDialog" 
        @close="closeCustomerPopup" 
        @setCustomerData="setCustomerData" 
        :customerInfo="customerDetails" 
        @goNext="goToPaymentPage"
        @skipCustomerDetails="skipCustomerDetails"
    />
    <PosPaymentModel 
        :posPaymentDialog="posPaymentDialog" 
        @payed="openInvoice"
        @close="closePosPaymentModel" 
        :customerInfo="customerDetails"
        :cartItems="cartItems"
    />
    <order-details
      :invId="invoiceModel.invoiceId"
      :invIds="invoiceModel.invoiceIds"
      :eInvId="eInvoiceModel.invoiceId"
      :payInvId="paymentModel.invoiceId"
      @close="closeMainComponent"
    />
    </v-container>
</template>

<script>
import PosProductItem from "@/views/Pos/PosProductItem.vue";
import PosCustomerPopup from "@/views/Pos/PosCustomerPopup.vue";
import PosPaymentModel from "@/views/Pos/PosPaymentModel.vue";
import OrderDetails from "../../components/Order/OrderDetails.vue";
import images from "@/utils/images";
export default {
    components: {
        PosProductItem,PosCustomerPopup,PosPaymentModel,OrderDetails
    },
    data() {
        return {
            image_path:'/../../src/assets/images/pos-img/1.png',
            cartItems: [],
            categories: [],
            products: [],
            right: null,
            productNameSearch:"",
            selectedCategory:'all',
            selectedTab:null,
            quantity:1,
            customerDialog: false,
            posPaymentDialog: false,
            customerDetails: {},

            invoiceModel: {
                invoiceId: null,
                invoiceIds: [],
                type: "details",
                orderIds: [],
            },
            eInvoiceModel: { invoiceId: null, type: "details", orderIds: [] },
            olModel: { invoiceId: null, type: "details", orderIds: [] },
            paymentModel: { invoiceId: null, type: "details", orderIds: [] },
            page: 1,
            perPage: 12,
            totalPages: 1,
            refreshProductItem: 0,
        }
    },
    watch: {
        page() {
            this.getProductList();
        },
    },
    mounted() {
        if (this.$store.getters.getVenueServices.status == false) {
            this.$store.dispatch("loadVenueServices").then(() => {
                this.getCategoryList();
            });
        } else {
            this.getCategoryList();
        }
        if (this.$store.getters.getTaxTypes.status == false) {
            this.$store.dispatch("loadTaxTypes");
        }
        this.getProductList();
    },
    computed: {
        venueServiceId() {
            const filteredService = this.$store.getters.getVenueServices.data.find(item => item.name.toLowerCase() === "pos");
            if (filteredService) {
                return filteredService.id;
            } else {
                return null;
            }
        }, 
        taxTypes() {
            return this.$store.getters.getTaxTypes.data;
        },
        calculateCartSummary(){
            const cartSummary = {
                tax: 0.0,
                subtotal: 0.0,
                total: 0.0, 
            }
            if(this.cartItems){
                let tax = 0; 
                let subtotal = 0; 
                let total = 0;
                this.cartItems.forEach( (item) => {
                    tax += (item.tax_amount * item.quantity);
                    subtotal += (item.price * item.quantity);
                    total += (item.price * item.quantity) + (item.tax_amount * item.quantity);
                });
                cartSummary.tax = tax;
                cartSummary.subtotal = subtotal;
                cartSummary.total = total;
            }
            return cartSummary;
        },
    },
    methods: {
        incrementQty(cartIndex) {
            if(this.cartItems[cartIndex].enable_retail_inventory){
                if(this.cartItems[cartIndex].stock_level < this.cartItems[cartIndex].quantity + 1){
                    this.showError("Only "+this.cartItems[cartIndex].stock_level+" quantity available in stock");
                    return;
                }else{
                    this.cartItems[cartIndex].quantity++;
                }
            }else{
                this.cartItems[cartIndex].quantity++;
            }
        },
        decrementQty(cartIndex) {
            if (this.cartItems[cartIndex].quantity > 1) {
               this.cartItems[cartIndex].quantity--;
            }else if(this.cartItems[cartIndex].quantity == 1){
                this.removeCartItem(cartIndex);
            }
        },
        clearSearch() {
            setTimeout(() => {
                this.getProductList(this.currentYear, true);
            }, 100);
        },
        handleTabChange(newTabIndex) {
            // Handle tab change here
            this.page = 1;
            if(this.categories[newTabIndex].id == 0 && this.selectedCategory != "all"){
                this.selectedCategory = 'all';
                this.getProductList();
            }else if(this.categories[newTabIndex].id > 0){
                this.selectedCategory = this.categories[newTabIndex].id;
                this.getProductList();
            }
            
        },
        getProductList() {
            this.showLoader("Loading");
            let url = `venues/pos?page=${this.page}&per_page=${this.perPage}&category=${this.selectedCategory}`;
            url += `${this.productNameSearch ? "&product_name=" + this.productNameSearch : ""}`;
            this.$http.get(url).then((response) => {
                if (response.status == 200) {
                    this.products = [];
                    const data = response.data.data;
                    if(data && data.length){
                        this.products = data;
                        this.totalPages = response.data.total_pages;
                        this.$nextTick(() => {
                            this.refreshProductItem++;
                        });
                    }else{
                        this.products = [];
                        this.totalPages = 1;
                    }
                    this.hideLoader();
                }
            }).catch((error) => {
                 this.hideLoader();
                this.errorChecker(error);
            });
            
        },
        getImage(image,type = 'product'){
             return image
                ? this.s3BucketURL+image
                : type == 'product'?images['default']:images['empty_cart'];
        },
        clearCart(){
            this.cartItems = [];
        },
        addToCart(data){
            this.addOrUpdateCartItem(data.product_id,data.vp_id)
        },
        addOrUpdateCartItem(product_id, vp_id = null) {
            let foundProduct = this.products.find(product => product.id === product_id);
            let mainProductName = "";
            let productImage = foundProduct.image;
            if (vp_id) {
                mainProductName = foundProduct.name;
                if (foundProduct.variants && foundProduct.variants.length) {
                    foundProduct = foundProduct.variants.find(v => v.id === vp_id);
                    if (foundProduct) {
                        mainProductName = foundProduct.name;
                    }
                }
            }
            if(foundProduct){
                /** check product on cartItems */
                const existingProduct = this.cartItems.find(item => item.id === foundProduct.id);
                if(foundProduct.enable_retail_inventory){
                    if(existingProduct && foundProduct.stock_level <  existingProduct.quantity + 1){
                        this.showError("Only "+foundProduct.stock_level+" quantity available in stock");
                        return;
                    }else{
                        if(foundProduct.stock_level < 1){
                            if(foundProduct.stock_level == 0){
                                this.showError("Product Out Of Stock");
                            }else{
                                this.showError("Only "+foundProduct.stock_level+" quantity available in stock");
                            }
                            
                            return;
                        }
                    }
                }
                if (existingProduct) {
                    existingProduct.quantity += 1;
                } else {
                    mainProductName = foundProduct.name;
                    // If the product does not exist in the cart, add it to the cart items
                    this.cartItems.push({
                        id: foundProduct.id,
                        name:mainProductName,
                        price:foundProduct.price,
                        quantity: 1,
                        tax_amount:foundProduct.tax_amount,
                        total_price:foundProduct.total_price,
                        category:foundProduct.category,
                        image:productImage,
                        vp_id:vp_id,
                        enable_retail_inventory:foundProduct.enable_retail_inventory,
                        stock_level:foundProduct.stock_level
                    });
                }
            }

        },
        removeCartItem(cartIndex){
            this.cartItems.splice(cartIndex, 1);
        },
        getCategoryList() {
            this.showLoader("Loading");
            if (this.venueServiceId) {
                let url = `venues/pos/categories?venue_service_id=${this.venueServiceId}`;
                this.$http.get(url).then((response) => {
                    if (response.status == 200) {
                        const data = response.data.data;
                        if (data && data.length) {
                            this.categories = [{ id: 0, name: 'All' }, ...data];
                        } else {
                            this.categories = [];
                        }
                        this.hideLoader();
                    }
                }).catch((error) => {
                    this.hideLoader();
                    this.errorChecker(error);
                });
            } else {
                this.categories = [];
            }
        },
        showCustomerPopup(){
            this.customerDialog = true;
        },
        closeCustomerPopup() {
            this.customerDetails = {};
            this.customerDialog = false;
        },
        setCustomerData(data) {
            if (data && !this.customerDetails.walkin) {
                this.$set(this.customerDetails, "walkin", false);
                this.$set(this.customerDetails, "customer_type", "normal");
                this.$set(this.customerDetails, "email", data.email);
                this.$set(this.customerDetails, "name", data.name);
                this.$set(this.customerDetails, "mobile", data.mobile);
                this.$set(this.customerDetails, "first_name", data.first_name);
                this.$set(this.customerDetails, "last_name", data.last_name);
                this.$set(this.customerDetails, "customer_id", data.customer_id);
                if (!data.first_name && data.name) {
                    let name = data.name.replace(/\s\s+/g, " ");
                    name = name.trim();
                    let index = name.indexOf(" ");
                    this.$set(this.customerDetails, "first_name", data.name);
                    if (index != -1) {
                        let first_name = name.substr(0, index).trim();
                        let last_name = name.substr(index + 1).trim();
                        this.$set(this.customerDetails, "first_name", first_name);
                        this.$set(this.customerDetails, "last_name", last_name);
                    } else {
                        this.$set(this.customerDetails, "last_name", "");
                    }
                }
                this.$forceUpdate();        
            }
        },
        goToPaymentPage(data) {
            if (data) {
                this.$set(this.customerDetails, "walkin", false);
                this.posPaymentDialog = true;        
            }
        },
        skipCustomerDetails() {
            this.posPaymentDialog = false;
            this.customerDetails = {};
            this.$set(this.customerDetails, "customer_type", "walk-in");
            this.$set(this.customerDetails, "walkin", true);
            this.$set(this.customerDetails, "customer_id", null);
            this.$set(this.customerDetails, "last_name", null);
            this.$set(this.customerDetails, "mobile", null);
            this.$set(this.customerDetails, "email", null);
            this.$set(this.customerDetails, "name", null);
            this.$set(this.customerDetails, "first_name", "Walk In Customer");
            this.posPaymentDialog = true;
            
            /** set invoice data */
            // this.$nextTick(() => {
            //     console.log("next tick working");
            //     console.log(this.customerDetails);
            // });
             
        },
        openInvoice(invoice_id, type = "view") {
            this.customerDetails = {};
            this.posPaymentDialog = false;
            this.customerDialog = false;
            this.showLoader("Loading..");
            this.$http
            .get("venues/invoices/" + invoice_id)
            .then((response) => {
                if (response.status == 200) {
                    this.hideLoader();
                    let data = response.data.data;
                    if (type == "view") {
                        this.cartItems = [];
                        this.invoiceData = data;
                        this.invoiceModel.invoiceId = data.id;
                        this.invoiceModel.orderIds = [];
                        this.getProductList();
                        
                    } else if (type == "edit") {
                        this.invoiceModel.invoiceId = null;
                        this.paymentModel.invoiceId = null;
                        this.eInvoiceData = data;
                        this.eInvoiceModel.invoiceId = data.id;
                        this.paymentModel.invoiceId = null;
                    }
                }
            })
            .catch((error) => {
                this.hideLoader();
                this.errorChecker(error);
            });
        },
        closeInvoiceModel() {
            this.invoiceModel.invoiceId = null;
        },
        openFullRefundInvoice(invoice_id) {
            // this.invoiceModel.invoiceId = null;
            this.paymentModel.invoiceId = null;
            this.oiInvoiceModel.invoiceId = null;
            this.showLoader("Loading..");
            this.$http
            .get("venues/invoices/refund-partial/" + invoice_id)
            .then((response) => {
            if (response.status == 200) {
                this.hideLoader();
                let data = response.data.data;
                this.orderItemsData = data;
                this.oiInvoiceModel.invoiceId = data[0].id;
            } else {
                this.showError("Unable to load items");
            }
            })
            .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
            });
        },
        openPartialRefundInvoice(invoice_id) {
            // this.invoiceModel.invoiceId = null;
            this.paymentModel.invoiceId = null;
            this.oiInvoiceModel.invoiceId = null;
            this.showLoader("Loading..");
            this.$http
                .get("venues/invoices/refund-partial/" + invoice_id)
                .then((response) => {
                if (response.status == 200) {
                    this.hideLoader();
                    let data = response.data.data;
                    this.orderItemsData = data;
                    this.oiInvoiceModel.invoiceId = data[0].id;
                } else {
                    this.showError("Unable to load items");
                }
                })
                .catch((error) => {
                this.hideLoader();
                this.errorChecker(error);
            });
        },
        closeMainComponent() {
            this.invoiceModel.invoiceId = null;
            this.invoiceModel.invoiceIds = [];
            this.eInvoiceModel.invoiceId = null;
            this.paymentModel.invoiceId = null;
            this.getProductList();
        },
        closePosPaymentModel() {
            this.customerDetails.walkin = false;
            this.posPaymentDialog = false
        },
    },
};
</script>
<style scoped>
.pos .pos-right-drawer{
    position:relative;
    width:98%;
}
.pos .fixed-height-container {
    height: 90vh;
    position: fixed;
    top: 66px;
    right: 2px;
    width: 20%;
    bottom: 40px;
    max-width: 250px;

}
.v-list.cart-sidebar-item {
    height: 70%;
    overflow-y: scroll;
}

 /deep/ {
    .pos .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
        height: 38px;
    }
    .pos .main-color{
        color: #4FAEAF;
    }
 }
.ptBtn a{
    min-width: 200px;
    padding: 8px 10px;
    display: block;
    text-align: center;
    border-radius: 4px;
    border: 1px solid rgba(17, 42, 70, 0.10);
    background: #FFF;
}
.clear-cart-text{
    font-size:12px;
    color:#666;
    cursor:pointer;
}
.pos .v-text-field .v-text-field__details {
    margin-bottom: 0px;
    display: none;
}

.pos .v-tabs:not(.v-tabs--vertical) .v-tab {
    white-space: normal;
    margin-right: 10px;
    min-width: 50px;
    height: 40px;
    padding: 10px 16px;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid rgba(17, 42, 70, 0.10);
    background: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;   
    color: var(--black, #333); 

}
.pos .v-tab.v-tab--active{
    border: 1px solid #4FAEAF !important;
    background: rgba(79, 174, 175, 0.10) !important;
    color: #4FAEAF !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
/deep/ {
    .pos .v-tabs.cat-tab > div.v-tabs-bar {
        background-color: transparent;
    }
    .pos .v-tabs-slider {
        display: none;
    }
    .pos .product-box {
        width: 100%;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid #EAEAEA;
        background: #FFF;
        padding:4px;
        cursor:pointer;
    }
    .pos .product-box .product-image{
        min-height: 140px;
        border-radius: 4px;
        padding:4px;
        position: relative;
    }
    .pos .product-box .product-image img {
        /* aspect-ratio: 3/2; */
        width: 100%;
        border-radius: 4px;
    }
    .pos .product-box .product-meta{
        padding: 12px 10px  10px 12px;
    }
    .pos .product-box .product-meta .product-title{
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        padding: 4px 0px;
        min-height: 40px;
    }
    .pos .product-box .product-meta .product-price {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 4px 0px;
    }
    .pos .product-box .pos-title-text{
        Weight:600;
        font-size:14px;
        color:Black;
    }
    .pos .product-box .pos-small-text{
        Weight:400;
        font-size:12px;
        color:Grey;
    }
    .pos .product-box .product-price.main-color > span {
        display: inline-block;
    }

    .pos .product-box span.price {
        width: 50%;
    }

    .pos .product-box span.p-variant {
        width: 49%;
    }
    .pos .product-box .product-meta span.display-block {
        display: block;
        font-weight: 500;
        font-size: 12px;
        padding-top: 10px;
    }
}  
.pos .cart-product-title {
    color: var(--dark-blue, #112A46);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pos .cart-product-description {
    padding: 10px;
}
.pos .cart-product-image {
    width: 20%; /* Adjust as per your requirements */
    height: 56px; /* Same as width to make it square */
    margin: 5px;
    border-radius:4px;
    border:1px solid #D9D9D9;
}
.pos .cart-product-image img{
    width:100%;
    height: 100%;

}
/* Add custom styles to control the layout */
.cart-product-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* Optional: Add styles for the quantity and plus-minus buttons */
.cart-product-qty {
  display: flex;
  align-items: center;
}
.pos .input-qty {
    padding: 10px;
    text-align: center;
    color: #000;
}
.plus-qty button,
.minus-qty button {
  /* Add your styles for the plus-minus buttons here */
    border: 1px solid #000 !important;
}

.pos .v-btn--icon.v-size--default .v-icon,.pos .v-btn--fab.v-size--default .v-icon {
    height: 24px;
    font-size: 16px;
    width: 24px;
}
.pos button.v-btn.v-btn--icon.v-btn--round.theme--light.v-size--default {
    width: 16px;
    height: 16px;
}
.cart-product-list-item.v-list-item {
    padding: 0px;
    margin: 0px 10px;
    border-bottom: 1px solid #F1F1F1;
}
.pos .cart-product-summary {
    border-radius: 9px;
    background: #F8FAFB;
    padding: 0px 10px;
    margin: 0px 10px;
    position: fixed;
    bottom: 20px;
    width: 95%;
    padding: 20px;
}

.pos .cart-subtotal-price {
    text-align: right;
    color: #333;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.pos .cart-total-price {
    text-align: right;
    color: var(--black, #333);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.pos button.cart-checkout-btn {
    display: block;
    width: 100%;
    border-radius: 4px;
    background: #112A46;
    height: 48px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #FFF;
}

.pos .cart-subtotal {
    color: rgba(51, 51, 51, 0.70);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.pos .cart-total {
    color: var(--black, #333);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.empty-cart img {
    width: 100px;
    height: 100px;
    display: block;
    margin: 40% 30%;
}
/* Styles for tablet screens */
@media screen and (max-width: 1920px) {
    .v-list.cart-sidebar-item {
        height: 60%;
    }
}
/* Styles for tablet screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .v-list.cart-sidebar-item {
        height: 60%;
    }
}
</style>
